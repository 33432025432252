import type { SVGProps } from 'react';

const SvgRoundedLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <g clipPath="url(#rounded-logo_svg__a)">
      <rect width={28} height={28} fill="#fff" rx={14} />
      <path
        fill="#000"
        d="M24.73 8.075a4.54 4.54 0 0 1-4.257 4.53c-.092.014-.19.014-.287.014a4 4 0 0 1-.484-.026c-.829-1.28-2.187-1.966-3.512-2.638a2 2 0 0 0-.183-.091 4.604 4.604 0 0 1-.366-1.789 4.543 4.543 0 0 1 4.545-4.544 4.543 4.543 0 0 1 4.543 4.544"
      />
      <path
        fill="#9EF22E"
        d="M23.815 25.93q-.216.588-.542 1.137a7.4 7.4 0 0 1-.646.933H5.74a6 6 0 0 1-.568-.568c-1.208-1.403-1.979-3.323-1.926-5.705.953-6.633 2.089-7.821 2.089-7.821s2.526 5.764 3.708 1.971c.28-.9.437-1.743.542-2.546.209-1.638.228-3.12.737-4.687.288-.895.745-1.809 1.489-2.794 0 0 .627 4.315 2.448 5.549 1.815 1.24 4.975 1.893 5.686 5.118.712 3.231 1.58.653 1.58.653s.392 1.142 1.736 2.807c.595.744 1.084 3.323.555 5.954"
      />
      <path
        fill="#000"
        d="M9.584 13.33c.411-1.416.542-2.682.66-3.897q.038-.399.077-.79c-.509 1.567-.528 3.05-.737 4.688"
      />
      <path
        fill="#000"
        d="M24.488 17.64q-.237-.423-.463-.836c-.68-1.228-1.11-1.991-1.476-2.24l-.352-.241-.137.405c-.131.378-.373.777-.47.81 0 0-.275-.118-.569-1.463a5.3 5.3 0 0 0-.548-1.469c-.9-1.665-2.52-2.487-3.989-3.231q-.39-.197-.75-.392a9 9 0 0 1-.85-.503c-1.775-1.214-2.44-5.595-2.44-5.64l-.118-.77-.47.62c-1.841 2.435-2.044 4.498-2.26 6.678-.123 1.26-.254 2.56-.71 4.008-.223.712-.503 1.117-.79 1.13-.725.059-1.913-1.874-2.514-3.251l-.228-.51-.333.45c-.04.053-1.058 1.476-2.063 8.514-.34 2.396-.196 4.393.444 6.019a7.2 7.2 0 0 0 1.28 2.128c.038.046.084.098.136.144h.92a6 6 0 0 1-.567-.568c-1.208-1.404-1.782-3.258-1.73-5.64.013-.627.065-1.287.163-1.992.66-4.615 1.319-6.757 1.678-7.65.542 1.09 1.658 3.009 2.794 3.009h.052c.6-.033 1.064-.568 1.384-1.587a3 3 0 0 0 .072-.241c.209-1.639.228-3.12.738-4.688.189-1.658.47-3.192 1.612-4.929.287 1.391 1.051 4.257 2.579 5.301.444.307.953.575 1.495.85.059.025.124.058.183.09 1.325.673 2.683 1.358 3.512 2.638.307.457.535.992.679 1.626.293 1.338.652 1.945 1.162 1.971.418.033.73-.385.92-.757.28.405.646 1.07.986 1.684q.225.421.47.842c.594 1.051 1.13 4.838-.104 7.971q-.218.587-.542 1.136a7.4 7.4 0 0 1-.647.934h.823c.144-.19.268-.392.392-.6 2.063-3.546 1.384-8.448.646-9.76"
      />
      <path
        fill="#000"
        d="M12.541 18.103a.587.587 0 1 0 0-1.175.587.587 0 0 0 0 1.175M15.14 18.103a.587.587 0 1 0 0-1.175.587.587 0 0 0 0 1.175"
      />
      <path
        fill="#fff"
        d="m20.199 5.497-1.835 2.768c-.039.059 0 .137.072.137h1.639c.052 0 .09.046.084.098l-.222 1.847c-.013.092.111.13.157.06l1.913-2.984c.04-.06 0-.131-.072-.131h-1.671a.086.086 0 0 1-.085-.098l.17-1.625a.086.086 0 0 0-.157-.06z"
      />
      <path
        fill="#000"
        d="M13.194 21.12c-1.201 0-1.933-.889-2.174-1.457a.486.486 0 1 1 .894-.385c.053.124.562 1.169 1.822.777a.486.486 0 0 1 .613.32.49.49 0 0 1-.32.614q-.45.137-.842.137z"
      />
    </g>
    <defs>
      <clipPath id="rounded-logo_svg__a">
        <rect width={28} height={28} fill="#fff" rx={14} />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRoundedLogo;
