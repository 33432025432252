import type { SVGProps } from 'react';

const SvgDescription = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="#101828"
      d="M10.25 19.75h7.5v-1.5h-7.5zm0-4h7.5v-1.5h-7.5zM8.308 23.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.283V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h7.942l5.25 5.25v11.942c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525zm7.192-13V6H8.308a.294.294 0 0 0-.212.096.294.294 0 0 0-.096.212v15.384c0 .077.032.148.096.212a.294.294 0 0 0 .212.096h11.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.212V10.5z"
    />
  </svg>
);
export default SvgDescription;
