import type { SVGProps } from 'react';

const SvgHot = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M6.417 16.334h.75zm15.166 0h-.75zm0 0h.75zm0 .006h-.75zm-.004.255-.747-.07-.003.044zm-.573 2.641-.693-.287zm-9.908 4.104.287-.692zm-4.104-4.104.693-.287zM17.99 7.666l-.509-.55zm.186 0 .509-.552zm-1.41 1.091-.615.43zm.249.005.597.454zM13.335 5.3l.394.638zm.159.007-.448.602zm-.553-.645c-.424.262-2.224 1.429-3.924 3.387s-3.35 4.765-3.35 8.286h1.5c0-3.032 1.419-5.502 2.982-7.302s3.223-2.874 3.58-3.095zm4.44 3.667c-1.453-2.082-3.047-3.332-3.44-3.624l-.895 1.204c.319.237 1.777 1.375 3.105 3.279zm.231.888c.408-.537.748-.87.887-.999l-1.018-1.102a9.3 9.3 0 0 0-1.063 1.194zm.055-1c.474.438 3.166 3.142 3.166 8.117h1.5c0-5.558-3.014-8.633-3.648-9.218zm3.166 8.118v.002l1.5-.003v-.001zm0 0v.005l1.5.001v-.005zm0 .006v.16h1.5v-.16zm0 .16v.025l1.493.14q.007-.082.007-.165zm-.004.07a6.8 6.8 0 0 1-.516 2.38l1.386.573a8.3 8.3 0 0 0 .63-2.902zm-.516 2.38a6.8 6.8 0 0 1-1.481 2.216l1.06 1.06a8.3 8.3 0 0 0 1.807-2.703zm-1.481 2.216a6.8 6.8 0 0 1-2.217 1.482l.574 1.385a8.3 8.3 0 0 0 2.704-1.806zm-2.217 1.482c-.829.343-1.718.52-2.615.52v1.5a8.3 8.3 0 0 0 3.19-.635zm-2.615.52a6.8 6.8 0 0 1-2.615-.52l-.574 1.385a8.3 8.3 0 0 0 3.19.635zm-2.615-.52a6.8 6.8 0 0 1-2.217-1.482l-1.06 1.06a8.3 8.3 0 0 0 2.703 1.807zm-2.217-1.482a6.8 6.8 0 0 1-1.481-2.217l-1.386.574a8.3 8.3 0 0 0 1.807 2.704zM7.687 18.95a6.8 6.8 0 0 1-.52-2.615h-1.5c0 1.095.215 2.178.634 3.19zM18.499 8.217a.615.615 0 0 1-.832 0l1.018-1.103a.885.885 0 0 0-1.204.001zm-2.348.97a.904.904 0 0 0 1.461.029l-1.194-.907a.596.596 0 0 1 .963.02zm-2.422-3.25a.61.61 0 0 1-.683-.03l.895-1.203a.89.89 0 0 0-1-.043z"
    />
    <path
      fill="currentColor"
      d="m18.082 19.741-.75.016v.001zm-8.165.093h-.75zm3.999-6.355-.452-.598zm.168 0 .452-.598zm-.62-.598a14.4 14.4 0 0 0-2.347 2.265c-.968 1.183-1.95 2.83-1.95 4.688h1.5c0-1.344.725-2.655 1.611-3.738a13 13 0 0 1 2.09-2.018zm5.368 6.845c-.037-1.83-1.018-3.45-1.977-4.614a14.4 14.4 0 0 0-2.32-2.231l-.902 1.197c.265.2 1.197.935 2.064 1.987.879 1.067 1.608 2.361 1.635 3.692zm-1.5.032.001.075h1.5l-.001-.108zm.001.075A3.333 3.333 0 0 1 14 23.167v1.5a4.833 4.833 0 0 0 4.833-4.834zM14 23.167a3.333 3.333 0 0 1-3.333-3.333h-1.5A4.833 4.833 0 0 0 14 24.667zm-4.834-3.333c0 .412.335.75.75.75v-1.5a.75.75 0 0 1 .75.75zm1.5 0a.75.75 0 0 0-.75-.75v1.5a.75.75 0 0 1-.75-.75zm3.701-5.756a.61.61 0 0 1-.735 0l.904-1.197a.89.89 0 0 0-1.072 0z"
    />
  </svg>
);
export default SvgHot;
