import type { SVGProps } from 'react';

const SvgEyeOff = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="#98A2B3"
      d="m18.402 15.135-1.31-1.31c.175-.966-.1-1.835-.827-2.606-.726-.771-1.617-1.07-2.673-.894l-1.31-1.31A4.027 4.027 0 0 1 14 8.66c1.324 0 2.447.462 3.371 1.386.924.923 1.385 2.047 1.385 3.37 0 .33-.03.636-.088.92-.06.285-.148.55-.266.8m3.71 3.63-1.287-1.207a12.776 12.776 0 0 0 1.969-1.852 10.387 10.387 0 0 0 1.473-2.29c-.973-1.963-2.368-3.524-4.186-4.68C18.263 7.577 16.236 7 14 7c-.564 0-1.118.039-1.662.117-.545.077-1.08.194-1.605.35l-1.36-1.36c.738-.293 1.491-.51 2.26-.648.769-.14 1.558-.21 2.367-.21 2.734 0 5.2.755 7.397 2.262 2.197 1.508 3.8 3.477 4.808 5.906a13.177 13.177 0 0 1-1.675 2.919 12.934 12.934 0 0 1-2.417 2.43m.943 6.749-4.72-4.685c-.599.222-1.262.403-1.99.543-.727.14-1.509.211-2.345.211-2.742 0-5.207-.754-7.397-2.261-2.19-1.508-3.792-3.476-4.808-5.905a12.96 12.96 0 0 1 1.67-2.885 13.262 13.262 0 0 1 2.252-2.32L2.486 4.944l1.23-1.23 20.569 20.57zM6.946 9.44c-.616.49-1.215 1.08-1.798 1.769a9.688 9.688 0 0 0-1.415 2.207c.973 1.963 2.368 3.524 4.186 4.68 1.818 1.158 3.845 1.736 6.081 1.736a9.42 9.42 0 0 0 1.586-.134c.527-.09.973-.183 1.34-.279l-1.477-1.512a4.211 4.211 0 0 1-1.45.265c-1.323 0-2.446-.462-3.37-1.385-.924-.924-1.385-2.048-1.385-3.371 0-.237.024-.484.071-.742.048-.258.113-.494.193-.708z"
    />
  </svg>
);
export default SvgEyeOff;
