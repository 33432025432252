import type { SVGProps } from 'react';

const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="#98A2B3"
      d="M14.003 18.173c1.322 0 2.444-.463 3.368-1.388.924-.925 1.385-2.05 1.385-3.371 0-1.322-.462-2.445-1.388-3.368-.925-.924-2.049-1.386-3.37-1.386-1.323 0-2.445.463-3.369 1.388-.924.926-1.385 2.05-1.385 3.371 0 1.322.462 2.445 1.388 3.369.925.923 2.049 1.385 3.37 1.385M14 16.567a3.038 3.038 0 0 1-2.231-.92 3.038 3.038 0 0 1-.919-2.23c0-.875.306-1.62.919-2.232A3.038 3.038 0 0 1 14 10.267c.875 0 1.619.306 2.231.918.613.613.919 1.357.919 2.232 0 .875-.306 1.618-.919 2.23a3.038 3.038 0 0 1-2.231.92m.002 5.016c-2.683 0-5.128-.74-7.334-2.22-2.206-1.48-3.83-3.462-4.873-5.946C2.837 10.932 4.46 8.95 6.666 7.47c2.206-1.48 4.65-2.22 7.332-2.22 2.683 0 5.128.74 7.334 2.22 2.206 1.48 3.83 3.462 4.873 5.947-1.042 2.484-2.666 4.466-4.871 5.946-2.206 1.48-4.65 2.22-7.332 2.22M14 19.833c2.197 0 4.215-.578 6.052-1.735a11.402 11.402 0 0 0 4.215-4.681 11.401 11.401 0 0 0-4.215-4.682C18.215 7.578 16.197 7 14 7c-2.197 0-4.215.578-6.052 1.735a11.401 11.401 0 0 0-4.215 4.682 11.402 11.402 0 0 0 4.215 4.68c1.837 1.158 3.855 1.736 6.052 1.736"
    />
  </svg>
);
export default SvgEye;
